// Global packages and functions
import { useState, useEffect } from 'react'
import { useRouteLoaderData } from '@remix-run/react'
import { addressIOClient } from '~/utils'

// Types
import type { Suggestion, AutocompleteAddress } from 'getaddress-api'
import { RootDataProps } from '~/types'

interface Props {
  address: string
  addressData: AutocompleteAddress | null
  setAddressData: (e: AutocompleteAddress | null) => void
}

// Main export
const useGetAddressIO = ({ address, addressData, setAddressData }: Props) => {
  // Global
  const { env } = useRouteLoaderData('root') as RootDataProps

  // Local state
  const [addressSuggestions, setAddressSuggestions] = useState<Suggestion[]>([])
  const [error, setError] = useState('')

  // Gets address suggestions based off user input
  const getAddressSuggestions = async (address: string) => {
    const api = addressIOClient(env.GETADDRESS_IO_API_KEY)
    const data = await api.autocomplete(address)

    if (data?.isSuccess) {
      const success = data.toSuccess()

      setAddressSuggestions(success?.suggestions)
    } else {
      setAddressSuggestions([])
      setError('No address suggestions found')
    }
  }

  // Fetches the specific address full data
  const getAddressData = async (id: string) => {
    const api = addressIOClient(env.GETADDRESS_IO_API_KEY)
    const data = await api.get(id)

    if (data?.isSuccess) {
      const success = data.toSuccess()

      setAddressData(success?.address)
    } else {
      setAddressData(null)
      setError('Address location data not found')
    }
  }

  // On change of address line, get suggestions
  useEffect(() => {
    if (address && !addressData) {
      const queryDelay = setTimeout(async () => {
        await getAddressSuggestions(address)
      }, 500)

      return () => clearTimeout(queryDelay)
    } else {
      setAddressSuggestions([])
    }
  }, [address, addressData])

  return {
    error,
    addressSuggestions,
    setAddressSuggestions,
    getAddressSuggestions,
    getAddressData,
  }
}

export default useGetAddressIO
