// Global packages and components
import { clsx } from 'clsx'
import { useGetAddressIO } from '~/hooks'

// Types
import type { AutocompleteAddress } from 'getaddress-api'

interface Props {
  label?: string
  disabled?: boolean
  address: string
  addressData: AutocompleteAddress | null
  dynamicWidth?: boolean
  setAddress: (e: string) => void
  setAddressData: (e: AutocompleteAddress | null) => void
}

// Main export
const AddressFinder = ({
  label,
  disabled,
  address,
  addressData,
  dynamicWidth = false,
  setAddress,
  setAddressData,
}: Props) => {
  const { error, addressSuggestions, setAddressSuggestions, getAddressData } =
    useGetAddressIO({ address, addressData, setAddressData })

  return (
    <div>
      {label && (
        <label
          className="mb-2 block font-medium"
          htmlFor="field-address-finder"
        >
          {label}
        </label>
      )}
      <div className="relative">
        <input
          id="field-address-finder"
          type="search"
          className={clsx(
            'w-full rounded-lg border border-neutral-300 p-3 outline-none',
            {
              'md:min-w-72 lg:min-w-96': !dynamicWidth,
            }
          )}
          placeholder="Type your address"
          value={address}
          disabled={disabled}
          autoComplete="off"
          onChange={e => {
            setAddress(e.target.value)
          }}
          onFocus={() => {
            setAddress('')
            setAddressData(null)
          }}
        />
        {addressSuggestions && addressSuggestions?.length > 0 && (
          <div className="absolute left-0 top-full z-30 mb-8 max-h-60 w-full overflow-y-auto bg-white p-4 shadow-md">
            {addressSuggestions.map((e, i) => (
              <button
                key={i}
                className="block w-full border-b border-neutral-100 py-1.5 text-left last-of-type:border-0 hover:text-teal-green"
                onClick={() => {
                  setAddress(e.address)
                  getAddressData(e.id)
                  setAddressSuggestions([])
                }}
              >
                <span className="hover:cursor-pointer">{e.address}</span>
              </button>
            ))}
          </div>
        )}
      </div>
      {error && <p className="mb-0 pt-2 text-xs text-red-600">{error}</p>}
    </div>
  )
}

export default AddressFinder
